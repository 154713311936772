<template>
  <v-card>
    <v-card-title>
      <v-alert
        text
        dense
        color="info"
        border="left"
        style="font-size: 14px"
        class="mb-0"
      >
        Hư hại: SSC-HUHAI-SSC → SKU / Barcode / Sub-Barcode
      </v-alert>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="closeDialog"> Đóng </v-btn>
      <v-btn color="primary" @click="submitOrder" v-if="isCanSave"> Lưu </v-btn>
    </v-card-title>
    <v-card-text>
      <div>
        <v-simple-table class="mb-5">
          <template v-slot:default>
            <thead class="v-data-table-header">
              <tr>
                <th role="columnheader" class="text-center">Tracking</th>
                <th role="columnheader" class="text-center">Mã vận chuyển</th>
                <th role="columnheader" class="text-center">MẤT NIÊM PHONG</th>
                <th role="columnheader" class="text-center">SKU</th>
                <th role="columnheader" class="text-center">Xuất</th>
                <th role="columnheader" class="text-center">Thiếu</th>
                <th role="columnheader" class="text-center">Hư hại</th>
                <th role="columnheader" class="text-center">TT. Kho</th>
                <th role="columnheader" class="text-center">TT. Vận chuyển</th>
                <th role="columnheader" class="text-center">TT. Tổng hợp</th>
              </tr>
            </thead>
            <tbody>
              <tr class="text-center">
                <td>{{ order.tracking_id }}</td>
                <td>
                  {{ order.short_delivery_order_id || order.delivery_order_id }}
                </td>
                <td>
                  <v-switch
                    class="switch-center"
                    v-model="order.is_lost_seal"
                  ></v-switch>
                </td>
                <td>{{ order.total_sku }}</td>
                <td>{{ order.total_quantity }}</td>
                <td>
                  <div :class="{ 'gr-error-count': summaryItems.missing != 0 }">
                    {{ summaryItems.missing }}
                  </div>
                </td>
                <td>
                  <div :class="{ 'gr-error-count': summaryItems.damage != 0 }">
                    {{ summaryItems.damage }}
                  </div>
                </td>
                <td>{{ order.goods_issue_state_name }}</td>
                <td>{{ order.delivery_partner_state }}</td>
                <td>{{ order.delivery_state_name }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-text-field
          label="SKU / Barcode / Sub-Barcode"
          dense
          outlined
          clearable
          hide-details
          @keyup.enter="scanSku(sku)"
          autofocus
          v-model="sku"
        ></v-text-field>

        <v-simple-table class="mb-5">
          <template v-slot:default>
            <thead class="v-data-table-header">
              <tr>
                <th role="columnheader" class="text-center">SKU</th>
                <th role="columnheader" class="text-center">Barcode</th>
                <th role="columnheader" class="text-center">Hình</th>
                <th role="columnheader" class="text-center">Hàng hóa</th>
                <th role="columnheader" class="text-center">Đặc tả</th>
                <th role="columnheader" class="text-center">Size</th>
                <th role="columnheader" class="text-center">Xuất</th>
                <th role="columnheader" class="text-center">Nhận</th>
                <th role="columnheader" class="text-center">Lệch</th>
                <th role="columnheader" class="text-center">Hư hại</th>
                <th role="columnheader" class="text-center">Ghi chú</th>
                <th role="columnheader" class="text-center">Ghi chú khác</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, k) in order.items"
                :key="`checking_${k}_${item.id_goods}`"
                class="text-center"
              >
                <td>
                  {{ item.sku }}
                  <template v-if="item.uid">
                    <br />
                    <span class="error--text">{{ item.uid }}</span>
                  </template>
                </td>
                <td>
                  {{ item.customer_goods_barcode }}
                  <template v-if="item.special_barcode">
                    <br />
                    <span class="error--text">{{ item.special_barcode }}</span>
                  </template>
                </td>
                <td>
                  <ImageViewer v-if="item.url_images" :url="item.url_images" />
                </td>
                <td>{{ item.goods_name }}</td>
                <td>{{ item.description }}</td>
                <td>{{ item.size }}</td>
                <td>{{ item.quantity }}</td>
                <td>{{ item.receive_quantity }}</td>
                <td>
                  <div
                    :class="{
                      'gr-error-count':
                        item.quantity - item.receive_quantity != 0,
                    }"
                  >
                    {{ item.quantity - item.receive_quantity }}
                  </div>
                </td>
                <td>
                  <div :class="{ 'gr-error-count': item.damage != 0 }">
                    {{ item.damage }}
                  </div>
                </td>
                <td>
                  <v-autocomplete
                    v-model="item.reason"
                    :items="reasonsOptions"
                    class="c-input-xs"
                    label="Chọn ghi chú"
                    placeholder="Chọn ghi chú"
                    dense
                    outlined
                    hide-details
                    single-line
                    multiple
                  ></v-autocomplete>
                </td>
                <td>
                  <v-textarea
                    v-model="item.note"
                    class="c-input-xs"
                    rows="2"
                    label="Ghi chú khác"
                    placeholder="Ghi chú khác"
                    dense
                    outlined
                    hide-details
                    single-line
                  ></v-textarea>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <small v-if="errMsg" class="red--text">{{ errMsg }}</small>
    </v-card-text>

    <v-dialog v-model="dialogConfirm" persistent max-width="350px">
      <ConfirmBox
        v-if="dialogConfirm"
        title="Sản phẩm bị hư hại"
        description="Bạn có chắc chắn muốn xác nhận sản phẩm bị hư hại không?"
        label="SKU / Barcode / Sub-Barcode"
        placeholder="Quét lại SKU / Barcode / Sub-Barcode để xác nhận"
        :codes="checkingCodes"
        @cancel="cancelConfirm"
        @confirm="scanSku"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
import { BARCODE_HU_HAI, RETURN_RECEIVE_REASONS } from "@/libs/const";

export default {
  name: "DialogCheckingReceive",
  components: {
    ConfirmBox: () => import("@/components/common/ConfirmBox"),
    ImageViewer: () => import("@/components/common/ImageViewer"),
  },
  props: {
    checkingOrder: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    sku: null,
    confirm_sku: null,
    order: {},
    errMsg: "",
    isLoading: false,
    isDamage: false,
    dialogConfirm: false,
    lastItemScanned: {},
    reasonsOptions: [...RETURN_RECEIVE_REASONS],
    scannedUids: [],
    lastUidCode: null,
  }),
  computed: {
    checkingCodes() {
      const codes = [];
      if (!this.lastItemScanned || !this.lastItemScanned.id_goods) {
        return codes;
      }
      if (this.lastItemScanned.sku) {
        codes.push("" + this.lastItemScanned.sku);
      }
      if (this.lastItemScanned.customer_goods_barcode) {
        codes.push(this.lastItemScanned.customer_goods_barcode);
      }
      if (this.lastItemScanned.sub_barcode) {
        codes.push(this.lastItemScanned.sub_barcode);
      }
      if (this.lastUidCode) {
        codes.push(this.lastUidCode);
      }
      return [...codes];
    },
    checkingOrderCodes() {
      const codes = [];
      if (!this.order || !this.order.tracking_id) {
        return codes;
      }
      if (this.order.tracking_id) {
        codes.push(this.order.tracking_id);
      }
      if (this.order.delivery_order_id) {
        codes.push(this.order.delivery_order_id);
      }
      if (this.order.short_delivery_order_id) {
        codes.push(this.order.short_delivery_order_id);
      }
      return [...codes];
    },
    summaryItems() {
      let missing = 0;
      let damage = 0;
      if (!this.order.items || this.order.items.length === 0) {
        return 0;
      }
      this.order.items.forEach((item) => {
        damage += item.damage;
        if (item.is_in_order && item.quantity > item.receive_quantity) {
          missing += item.quantity - item.receive_quantity;
        }
      });
      return { missing, damage };
    },
    isCanSave() {
      if (!this.order || !this.order.items || this.order.items.length === 0) {
        return false;
      }
      let countErr = 0;
      const items = [...this.order.items];
      items.forEach((item) => {
        const damage = item.damage;
        const missing = item.quantity - item.receive_quantity;
        if ((damage || missing !== 0) && !item.reason && !item.note) {
          countErr++;
        }
      });
      return countErr <= 0;
    },
  },
  methods: {
    cancelConfirm() {
      this.isDamage = false;
      this.dialogConfirm = false;
      this.isLoading = false;
    },
    async getOrderDetail() {
      if (this.checkingOrder.items && this.checkingOrder.items.length > 0) {
        const order = { ...this.checkingOrder };
        let total_quantity = 0;
        const items = order.items.map((d) => {
          total_quantity += d.quantity;
          return d;
        });
        order.items = [...items];
        this.order = { ...order, total_sku: items.length, total_quantity };
        return false;
      }
    },
    async scanSku(confirm_sku = null) {
      if (this.isCanSave && this.checkingOrderCodes.includes(this.sku)) {
        this.submitOrder();
        return false;
      }
      if (confirm_sku) {
        this.sku = confirm_sku;
      }

      if (!this.sku) {
        return false;
      }
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      if (this.sku === BARCODE_HU_HAI) {
        this.sku = null;
        this.isLoading = false;
        this.isDamage = true;
        this.dialogConfirm = true;
        this.$root.$emit("playSuccessAudio");
        return false;
      }

      if (this.isDamage) {
        if (this.checkingCodes.includes(this.sku)) {
          const items = [...(this.order.items || [])];
          this.order.items = items.map((i) => {
            if (i.id_goods === this.lastItemScanned.id_goods) {
              i.damage += 1;
            }
            return i;
          });
          this.sku = null;
          this.isLoading = false;
          this.isDamage = false;
          this.dialogConfirm = false;
          this.$root.$emit("playSuccessAudio");
        } else {
          this.sku = null;
          this.isLoading = false;
          this.$vToastify.error("SKU / Barcode / Sub-Barcode không đúng");
          this.$root.$emit("playErrorAudio");
        }
        return false;
      }

      try {
        const { data } = await httpClient.post(
          "/goods-get-by-code-or-uid-and-customer",
          {
            code: this.sku,
            id_customer: this.order.id_customer,
          }
        );

        this.isLoading = false;
        this.lastUidCode = null;

        if (data.id_goods_special_barcode) {
          if (this.scannedUids.includes(data.id_goods_special_barcode)) {
            this.$vToastify.error("Mã đặc biệt đã được quét!");
            this.$root.$emit("playErrorAudio");
            this.sku = null;
            return false;
          }
          if (data.available > 0) {
            this.$vToastify.error("Hàng hóa chưa được xuất kho");
            this.$root.$emit("playErrorAudio");
            this.sku = null;
            return false;
          }
          this.lastUidCode = this.sku;
          this.scannedUids.push(data.id_goods_special_barcode);
        }

        const items = [...(this.order.items || [])];
        const item = items.find((i) => i.id_goods === data.id_goods);
        if (!item) {
          const newItem = { ...data };
          newItem.quantity = 0;
          newItem.receive_quantity = 1;
          newItem.damage = 0;
          newItem.is_in_order = false;
          this.lastItemScanned = { ...newItem };
          items.push(newItem);
        } else {
          item.receive_quantity += 1;
          this.lastItemScanned = { ...item };
        }
        this.order.items = [...items];
        this.sku = null;
        this.$root.$emit("playSuccessAudio");
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.sku = null;
        this.isLoading = false;
        this.$root.$emit("playErrorAudio");
      }
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
    submitOrder() {
      this.$emit("submit", {
        ...this.order,
        ...this.summaryItems,
        is_checked: true,
      });
      this.closeDialog();
    },
  },
  mounted() {
    this.getOrderDetail();
  },
};
</script>

<style scoped></style>
